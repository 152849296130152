// un-obfuscated, un-iffied version of bing universal ad tracking snippet
// wrapped in a function to allow for deferred call

import { deleteCookie, getCookie, setCookie } from "@fruugo/utilities";

const disabledBingTrackingCookieName = "_uetmsdns";

/* global UET */
export function initBing() {
  // Remove disable cookie if reallowing tracking
  if (getCookie(disabledBingTrackingCookieName)) {
    deleteCookie(disabledBingTrackingCookieName);
  }

  const initialised = window.uetq !== undefined;
  if (initialised) return;

  window.uetq = window.uetq || [];

  const onLoad = function () {
    var options = { ti: "5648258" };
    if (window.UET === undefined) return;
    options.q = window.uetq;
    window.uetq = new UET(options);
    window.uetq.push("pageLoad");
  };

  const script = document.createElement("script");
  script.src = "//bat.bing.com/bat.js";
  script.async = 1;
  script.onload = script.onreadystatechange = function () {
    var s = this.readyState;
    (s && s !== "loaded" && s !== "complete") ||
      (onLoad(), (script.onload = script.onreadystatechange = null));
  };

  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode.insertBefore(script, firstScript);
}

export const disableBingTracking = () => {
  // Disable tracking when opting out
  // https://help.ads.microsoft.com/#apex/ads/en/53056/2/#exp7118
  // Set the cookie named "_uetmsdns" with a value of 1. Advertisers are responsible for setting this cookie, and it must be set in a first-party context
  // (for example, in the advertiser's domain, such as contoso.com). This cookie is read by the client-side UET JavaScript during runtime, and if the
  // value is set to 1, no UET events will fire.

  if (!getCookie(disabledBingTrackingCookieName)) {
    setCookie(disabledBingTrackingCookieName, "1", 390);
  }
};
