// These should match the images sizes that get genearted in the initResponsiveImages below
// as they get sent to the backend to generate the API json

export const imageFormats = ["WEBP", "JPG"];
export const heroImagesSizes = [640, 768, 1024, 1366, 1632];
export const cmsMasonaryImagesSizes = [800];

export const initResponsiveImages = () => {
  // We have to require which images we would like the responsive-loader webpack plugin to generate
  // Hero images
  require.context(
    "../../images/home/hero?sizes[]=640,sizes[]=768,sizes[]=1024,sizes[]=1366,sizes[]=1632&format=jpg",
    false,
    /\.(jpe?g|png|webp)$/
  );
  require.context(
    "../../images/home/hero?sizes[]=640,sizes[]=768,sizes[]=1024,sizes[]=1366,sizes[]=1632&format=webp",
    false,
    /\.(jpe?g|png|webp)$/
  );

  // Other home images
  require.context("../../images/home?format=jpg", false, /\.(jpe?g|png|webp)$/);
  require.context(
    "../../images/home?format=webp",
    false,
    /\.(jpe?g|png|webp)$/
  );
};
