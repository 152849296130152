export function initClearpay() {
  if (
    window.clearpayPaymentMethodAvailable !== true ||
    window.pageType !== "product"
  )
    return;

  const script = document.createElement("script");
  script.src = "https://js.afterpay.com/afterpay-1.x.js";
  script.async = 1;

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(script);

  const element = document.querySelector("afterpay-placement");
  element && element.classList.remove("d-none");
}
