/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

 */

/* globals gtag:false */

import {
  sendGoogleAnalyticsEvent,
  createEcommerceProductPayload,
} from "../modules/analytics/google-analytics";

import {
  sendFruugoAnalyticsClickEvent,
  sendFruugoAnalyticsImpressionEvent,
} from "../modules/analytics/fruugo-analytics";

// send impressions for products in a carousel
export function sendImpressionsForProductTiles(tiles) {
  if (tiles.length === 0) return;
  const parent = tiles[0].parentNode;
  const siblings = Array.from(parent.childNodes).filter(
    (node) => node.nodeType === 1
  );
  const listName = parent.closest("[data-name]").dataset.name;
  // Only carousel items will have a parent with an endpoint attribute
  const isCarousel = parent.closest("[data-endpoint]")?.dataset.endpoint;

  const items = tiles
    .map((tile) => {
      if (shouldSendImpression(tile) === false) return;
      const index = siblings.indexOf(tile);
      const item = {
        category: tile.dataset.category,
        brand: tile.dataset.brand,
        name: tile.dataset.name,
        id: tile.dataset.productId,
        price: tile.dataset.price,
        list_name: listName,
        list_position: index + 1,
        google_business_vertical: "retail",
        source: tile.dataset.source,
        isCarousel: isCarousel ?? false,
      };

      const dimensions = {
        retailerName: tile.dataset.retailerName || "",
        retailerId: tile.dataset.retailerId || "",
      };

      tile.addEventListener(
        "click",
        () => sendClickForProductTile(item, dimensions),
        false
      );

      tile.classList.add("impression-sent");

      return { ...item, ...dimensions };
    })
    .filter(Boolean);

  if (items.length > 0) {
    gtag("event", "view_item_list", { items });
    sendFruugoAnalyticsImpressionEvent(items);
  }
}

/**
 * Should a given product tile count as an impression?
 * Is it visible on screen, and has it not already sent an impression?
 * @param {Element} tile
 * @returns {boolean}
 */
function shouldSendImpression(tile) {
  // already sent in some previous call
  return !tile.classList.contains("impression-sent");
}

/**
 * @param {String} sku
 */
export function sendBuyNowEvent(sku, quantity) {
  sendGoogleAnalyticsEvent(
    "legacy_event",
    "product interaction",
    "buy now pdp",
    sku.title
  );
  gtag("event", "add_to_cart", {
    items: createEcommerceProductPayload([{ quantity, sku }]),
  });
}

export function createProductImpressionObserver() {
  return new IntersectionObserver(
    (entries) => {
      const items = [];
      entries.forEach((entry) => {
        if (entry.isIntersecting === false) return;
        items.push(entry.target);
      });
      sendImpressionsForProductTiles(items);
    },
    { threshold: [0.5] }
  );
}

function sendClickForProductTile(item, dimensions) {
  sendGoogleAnalyticsEvent(
    "legacy_event",
    "product interaction",
    "product list click",
    item.list_name
  );

  gtag("event", "select_content", {
    content_type: "product",
    items: [item],
    ...dimensions,
  });

  sendFruugoAnalyticsClickEvent(item);
}

/**
 * @param {{}} skuInfo
 * @param {number} qty
 */
export function sendAddToCartEvent(sku, quantity) {
  sendGoogleAnalyticsEvent(
    "legacy_event",
    "product interaction",
    "add to basket",
    sku.title
  );
  gtag("event", "add_to_cart", {
    items: createEcommerceProductPayload([{ quantity, sku }]),
  });
}

const sendHeaderLinkGtagEvent = (link, event) => {
  return gtag("event", "top_nav_click", {
    nav_item: link.getAttribute("data-nav-item"),
    nav_destination: event.currentTarget.href,
  });
};

export function trackHeaderLinkClicks() {
  const headerLinks = document.querySelectorAll(".js-header-link");
  if (!headerLinks) return;

  headerLinks.forEach((link) => {
    link.addEventListener("click", function (event) {
      sendHeaderLinkGtagEvent(link, event);
    });
    link.addEventListener("auxclick", function (event) {
      // Mouse Middle Click
      if (event.button == 1) {
        sendHeaderLinkGtagEvent(link, event);
      }
    });
  });
}
export { createEcommerceProductPayload };
