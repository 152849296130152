export function fadeIn(element) {
    void element.offsetWidth; // Force reflow so the transition happens the first time it's added to the page
    element.classList.remove("fadeOut");
    element.classList.add("fadeIn");
}
export function fadeOut(element) {
    element.classList.remove("fadeIn");
    element.classList.add("fadeOut");
}
// Useful for clearing transitions, like when resizing the window and
// getting jank from fade transitions
export function clearFades(element) {
    element.classList.remove("fadeIn", "fadeOut");
}
export function slideIn(element) {
    element.classList.remove("slideOut");
    element.classList.add("slideIn");
}
export function slideOut(element) {
    element.classList.remove("slideIn");
    element.classList.add("slideOut");
}
export function clearSlides(element) {
    element.classList.remove("slideIn", "slideOut");
}
