/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

*/

import { createModal, closeModal } from "./modal.js";
import xhr from "./xhr.js";
import { initTranslation, i18n } from "./i18n.js";

export default async function initGeoIPModal() {
  // Current and local sites
  const {
    currentCountryDomain,
    geoIPDomain,
    geoIPRtl,
    currentCountry,
    geoIPCountry,
    geoIPLanguage,
    messagePropertiesResourceUrlForGeoIP,
  } = window;

  // Exit immediately if GeoIP modal has been previously dismissed or current country same as local country
  if (
    localStorage.getItem("geoIPModalDismissed") ||
    !geoIPDomain ||
    geoIPDomain === "" ||
    currentCountryDomain === geoIPDomain
  ) {
    return;
  }

  await initTranslation(messagePropertiesResourceUrlForGeoIP);

  const buildModalHtml = (
    primaryLink,
    productAvailable = true,
    productCategoryName = "",
    productCategoryUrl = ""
  ) => {
    const geoIpCountryString = i18n(
      "common.country." + geoIPCountry,
      undefined,
      geoIPLanguage
    );
    const currentCountryString = i18n(
      "common.country." + currentCountry,
      undefined,
      geoIPLanguage
    );

    let productNotAvailable = "";

    if (productAvailable === false) {
      productNotAvailable = `
                <div class="product-not-available text-center mt-32">
                    <p class="m-0 text-center">
                        <strong>${i18n(
                          "geoipmodal.productNotAvailable",
                          geoIpCountryString,
                          geoIPLanguage
                        )}</strong>
                    </p>
                    <a href="${productCategoryUrl}" class="d-block m-auto text-center">
                        ${i18n(
                          "header.shop.all",
                          productCategoryName,
                          geoIPLanguage
                        )}
                    </a>
                </div>`;
    }

    return `
            <div>
                <h2>${i18n(
                  "geoipmodal.greeting",
                  geoIpCountryString,
                  geoIPLanguage
                )}</h2>
                <p>${i18n(
                  "geoipmodal.currentlyShopping",
                  [currentCountryString, currentCountryDomain],
                  geoIPLanguage
                )}</strong></p>
                <p>${i18n(
                  "geoipmodal.localShopping",
                  [geoIpCountryString, geoIPDomain],
                  geoIPLanguage
                )}</p>
                <div class="modal-action-buttons mt-32 d-flex flex-column-reverse flex-sm-row justify-content-end">
                    <a href="#" class="btn btn-bordered">${i18n(
                      "geoipmodal.noStay",
                      currentCountryString,
                      geoIPLanguage
                    )}</a>
                    <a href="${primaryLink}" class="btn btn-success" target="_blank">${i18n(
      "geoipmodal.yesTakeMe",
      geoIPDomain,
      geoIPLanguage
    )}</a>
                </div>
                ${productNotAvailable}
            </div>
        `;
  };

  const attemptLaunchModal = (html) => {
    // if consent management is open, we need to wait
    if (document.querySelector(".consent-modal")) {
      document.addEventListener("DOMContentLoaded", function () {
        launchModal(html);
      });
    } else {
      launchModal(html);
    }
  };

  const launchModal = (html) => {
    const modalOpenClass = "modal-fade geo-ip-modal";
    createModal(
      html,
      `Close`,
      modalOpenClass,
      "modal-dialog-centered modal-lg",
      "",
      false
    );

    const modal = document.querySelector(".modal");
    const modalButtons = modal.querySelectorAll(".modal-action-buttons a");

    modalButtons.forEach((item) => {
      item.addEventListener("click", function () {
        localStorage.setItem("geoIPModalDismissed", "true");
        closeModal(modalOpenClass);
      });
    });

    const dialog = modal.querySelector(".modal-dialog");

    if (geoIPRtl === "true") {
      dialog.classList.add("dir-rtl");
    } else {
      dialog.classList.add("dir-ltr");
    }
  };

  const activateGeoIPModal = () => {
    // If product page, run check to see if product exists in local country and add footer note IF product not available in local country
    // Otherwise render standard modal on homepage/category/cart or on error
    if (window.productId && window.skuId) {
      xhr(
        `/marketplace/api/geoip/search/product/p-${window.productId}-${window.skuId}`,
        "get"
      )
        .then((response) => {
          const {
            productExistsInGeoIpCountry,
            productCategoryName,
            productCategoryURL,
            productLocationURL,
          } = JSON.parse(response.responseText);
          let modalHtml;
          if (productExistsInGeoIpCountry === false && productCategoryURL) {
            modalHtml = buildModalHtml(
              `https://${geoIPDomain}`,
              false,
              productCategoryName,
              productCategoryURL
            );
          } else if (
            productExistsInGeoIpCountry === true &&
            productLocationURL
          ) {
            modalHtml = buildModalHtml(productLocationURL);
          }
          attemptLaunchModal(modalHtml);
        })
        .catch(() => {
          const modalHtml = buildModalHtml(`https://${geoIPDomain}`);
          attemptLaunchModal(modalHtml);
        });
    } else {
      const modalHtml = buildModalHtml(`https://${geoIPDomain}`);
      attemptLaunchModal(modalHtml);
    }
  };

  setTimeout(activateGeoIPModal, 1500);
}
