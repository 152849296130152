import lineClamp from "line-clamp";
import { i18n } from "./i18n.js";

/**
 * @example <p class="js-read-more" data-lines-to-clamp="1">Hello world</p>
 */

const initReadMore = () => {
  const settings = {
    dataAttributeOriginalText: "data-original-text",
    containerSelector: "js-read-more",
    readMoreLinkSelector: "js-read-more-link",
    readLessLinkSelector: "js-read-less-link",
    defaultLinesToClamp: 3,
    devices: {
      mobile: "mobile",
    },
    getIcon(iconName) {
      return `<svg class="icon" style="fill:#000;"><use href="/marketplace/images/sprite-icon.svg#${iconName}"></use></svg>`;
    },
    get readMoreLink() {
      return `<button class="ReadMore d-flex ${
        this.readMoreLinkSelector
      }">${i18n("generic.show-more")} ${this.getIcon("chevron-down")}</button>`;
    },
    get readLessLink() {
      return `<button class="ReadMore d-flex ${
        this.readLessLinkSelector
      }">${i18n("generic.show-less")} ${this.getIcon("chevron-up")}</button>`;
    },
  };

  const initialClamp = () => {
    const containers = document.querySelectorAll(
      `.${settings.containerSelector}`
    );

    if (containers) {
      containers.forEach((container) => {
        const device = container.dataset.device;
        if (device && !matchesDevice(device)) return;

        const linesToClamp =
          container.dataset.linesToClamp || settings.defaultLinesToClamp;

        container.setAttribute(
          settings.dataAttributeOriginalText,
          container.innerHTML
        );

        const didClamp = lineClamp(container, linesToClamp, {
          ellipsis: " ",
        });

        if (didClamp) {
          container.innerHTML += settings.readMoreLink;
          bindReadMoreEvents(container, linesToClamp);
        }
      });
    }
  };

  const bindReadMoreEvents = (container, linesToClamp) => {
    const element = container.querySelector(
      `.${settings.readMoreLinkSelector}`
    );

    element.addEventListener(
      "click",
      () => {
        container.innerHTML = container.getAttribute(
          settings.dataAttributeOriginalText
        );
        container.innerHTML += settings.readLessLink;

        bindReadLessEvents(container, linesToClamp);
      },
      { once: true }
    );
  };

  const bindReadLessEvents = (container, linesToClamp) => {
    const element = container.querySelector(
      `.${settings.readLessLinkSelector}`
    );

    element.addEventListener(
      "click",
      () => {
        const didClamp = lineClamp(container, linesToClamp, {
          ellipsis: " ",
        });

        if (didClamp) container.innerHTML += settings.readMoreLink;

        bindReadMoreEvents(container, linesToClamp);
      },
      { once: true }
    );
  };

  initialClamp();
};

const matchesDevice = (devices, device) => {
  if (device == devices.mobile) {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    if (mediaQuery.matches) {
      return true;
    }
  }

  return false;
};

export default initReadMore;
