import { jsx as _jsx } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { select } from "@fruugo/utilities";
import { fadeIn, fadeOut } from "../animations";
export var backdropClass = ".backdrop";
export function showBackdrop() {
    var backdrop = select(backdropClass);
    if (!backdrop) {
        document.body.appendChild(_jsx(Backdrop, {}));
        backdrop = select(backdropClass);
    }
    fadeIn(backdrop);
}
export function hideBackdrop() {
    var backdrop = select(backdropClass);
    backdrop && fadeOut(backdrop);
}
export var Backdrop = function () {
    return _jsx("div", { class: backdropClass.replace(".", "") });
};
