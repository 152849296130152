/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

 */

import { i18n } from "./i18n.js";
import xhr from "./xhr.js";
import { toggleClass, stringToHtml } from "./utilities.js";
import { Logger, diffInDays, diffInHours, diffInMins } from "@fruugo/utilities";

const Log = new Logger(window.environment);

export {
  preloadJson,
  stickyElement,
  visualSelect,
  initAccordion,
  initToggleClassLinks,
  renderVoucherBanner,
};

function preloadJson() {
  const event = new CustomEvent("navigationReady");
  if (
    !sessionStorage.getItem("preloadedNavJson") ||
    sessionStorage.getItem("preloadedNavLang") !== window.currentLang
  ) {
    xhr(
      "/marketplace/api/site-navigation/header?language=" + window.currentLang
    )
      .then((data) => {
        sessionStorage.setItem("preloadedNavJson", data.response);
        sessionStorage.setItem("preloadedNavLang", window.currentLang);
        window.dispatchEvent(event);
      })
      .catch((error) => Log.error(error));
  } else {
    window.dispatchEvent(event);
  }

  if (
    !sessionStorage.getItem("preloadedFooterJson") ||
    sessionStorage.getItem("preloadedFooterLang") !== window.currentLang
  ) {
    xhr(
      "/marketplace/api/country-languages?language=" + window.currentLang
    ).then((data) => {
      sessionStorage.setItem("preloadedFooterJson", data.response);
      sessionStorage.setItem("preloadedFooterLang", window.currentLang);
      footerLinks(); // re-run footer links to update them
    });
  } else {
    footerLinks();
  }
}

function footerLinks() {
  document.querySelectorAll(".js-footer-expand").forEach((footerLink) => {
    footerLink.addEventListener("click", function (event) {
      event.preventDefault();
      const element = event.currentTarget;
      element.classList.toggle("footer-link--expanded");
      element.nextElementSibling.classList.toggle("d-block");
    });
  });

  if (sessionStorage.getItem("preloadedFooterJson")) {
    const data = JSON.parse(sessionStorage.getItem("preloadedFooterJson"));

    const countryNavItems = data.countries.map((item) => {
      if (item.country === window.currentCountry) {
        return `<li class="list-inline-item"><strong>${item.localisedDisplayName}</strong></li>`;
      }
      const { origin, pathname, search } = window.location;
      const encodedRedirectUrl = origin + pathname + encodeURIComponent(search);
      return `<li class="list-inline-item"><a href="/marketplace/api/redirect?url=${encodedRedirectUrl}&country=${item.country}">${item.localisedDisplayName}</a></li>`;
    });

    const countryList = stringToHtml(
      `<ul class="list-inline">${countryNavItems.join("")}</ul>`
    );
    const countriesContainer = document.querySelector(".js-countries");
    countriesContainer.innerHTML = "";
    countriesContainer.appendChild(countryList);

    const langNavItems = data.languages.map((item) => {
      if (item.language === window.currentLang) {
        return `<li class="list-inline-item"><strong>${item.localisedDisplayName}</strong></li>`;
      }

      let link = "";
      if (window.pageType === "search" && window.searchQuery) {
        link = `?q=${window.searchQuery}&language=` + item.language;
      } else {
        link = `?language=` + item.language;
      }
      return (
        `<li class="list-inline-item"><a href="${link}">` +
        item.localisedDisplayName +
        `</a></li>`
      );
    });

    const languagesList = stringToHtml(
      `<ul class="list-inline">${langNavItems.join("")}</ul>`
    );
    const languagesContainer = document.querySelector(".js-languages");
    languagesContainer.innerHTML = "";
    languagesContainer.appendChild(languagesList);
  }
}

function stickyElement(selector, activeFrom, activeClass) {
  const stickyElement = document.querySelector(selector);

  window.addEventListener("scroll", function () {
    if (window.pageYOffset >= activeFrom) {
      stickyElement.classList.add(activeClass);
    } else {
      stickyElement.classList.remove(activeClass);
    }
  });
  // hash links should be offset by sticky header height
  document
    .querySelectorAll(`a[href^="#"].js-smooth-scroll`)
    .forEach((anchor) => {
      anchor.addEventListener("click", function (event) {
        event.preventDefault();
        let anchor = event.currentTarget.getAttribute("href");
        if (anchor == "#") anchor = "body";
        const element = document.querySelector(anchor);
        if (element) {
          const offset = event.currentTarget.hash
            ? element.getBoundingClientRect().top + window.scrollY - 95
            : 0;
          window.scrollTo(0, offset);
        }
      });
    });
}

function visualSelect() {
  document.querySelectorAll(".VisualSelect").forEach((visualSelect) => {
    const select = visualSelect.previousElementSibling;

    const options = visualSelect.querySelectorAll("span");
    if (options && options.length > 0) {
      options.forEach((fakeOption) => {
        fakeOption.addEventListener("click", () => {
          const variant = fakeOption.dataset.variant;
          if (variant === undefined) return;
          if (fakeOption.getAttribute("disabled") !== null) return;
          select.querySelectorAll("option").forEach((option, ix) => {
            if (ix === parseInt(variant)) {
              option.selected = true;
              select.dispatchEvent(new Event("change"));
            }
          });
          select.classList.add("loading");
        });
      });
    }
  });
}

function initAccordion() {
  const accordions = document.querySelectorAll(".js-accordion");
  if (accordions) {
    accordions.forEach((el) => {
      const button = el.querySelector("button");
      const container = el.parentNode;
      button.addEventListener("click", () => toggleClass(container, "active"));
    });
  }
}

const initToggleClassLinks = () => {
  const containers = document.querySelectorAll(".js-toggle-class");

  if (containers) {
    containers.forEach((container) => {
      let toggled = false;
      const { toggleElement, toggleCssClass } = container.dataset;
      container.addEventListener("click", () => {
        const elements = document.querySelectorAll(toggleElement);
        elements.forEach((element) => {
          toggleClass(element, toggleCssClass);
        });
        toggled = !toggled;
      });
    });
  }
};

const renderVoucherBanner = () => {
  const voucherElement = document.querySelector(".js-voucher-banner");

  if (voucherElement == null) return;

  const { voucherExpiryDate, voucherDiscountPercentage } =
    voucherElement.dataset;
  const currentLocalTime = new Date();
  const expiryDate = new Date(parseInt(voucherExpiryDate));
  // Voucher expires at midnight whenever the expiry date is set
  expiryDate.setHours(23, 59, 59, 999);
  const localExpiryDate = new Date(
    expiryDate.setMinutes(
      expiryDate.getMinutes() + expiryDate.getTimezoneOffset()
    )
  );
  const differenceInDays = diffInDays(currentLocalTime, expiryDate);
  const differenceInHours = diffInHours(currentLocalTime, expiryDate);

  if (localExpiryDate.getTime() <= currentLocalTime.getTime()) {
    return;
  }

  const isMoreThan7Days = differenceInDays >= 7;
  const isLessThan7Days = differenceInDays < 7 && differenceInDays >= 1;
  const isToday = differenceInHours < 24;
  const voucherPercentage = voucherDiscountPercentage + "%";
  const formattedDate = localExpiryDate.toLocaleDateString(
    window.currentLang + "-" + window.currentCountry
  );
  const dateHours = diffInHours(currentLocalTime, expiryDate);
  const dateMinutes = diffInMins(currentLocalTime, expiryDate);

  const bannerText = isMoreThan7Days
    ? `${i18n("banner.more-than-seven-days", [
        voucherPercentage,
        formattedDate,
      ])}`
    : isLessThan7Days
    ? differenceInDays == 1
      ? `${i18n("banner.tomorrow", [voucherPercentage, formattedDate])}`
      : `${i18n("banner.less-than-seven-days", [
          voucherPercentage,
          differenceInDays,
          formattedDate,
        ])}`
    : isToday
    ? `${i18n("banner.today", [voucherPercentage, dateHours, dateMinutes])}`
    : "";

  const bannerClass = isMoreThan7Days
    ? "Banner--Green"
    : isLessThan7Days
    ? "Banner--Orange"
    : isToday
    ? "Banner--Red"
    : "";

  const template = `<div class="Banner ${bannerClass}">
<p class="Banner__Text">${bannerText}</p>
</div>`;

  voucherElement.innerHTML = template;
};
