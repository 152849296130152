export const initPayPalMessaging = () => {
  const {
    pageType,
    payPalClientId,
    defaultLang,
    currentLang,
    payPalPaymentMethodAvailable,
    payPalPayLaterMessagingAvailable,
    currentCurrencyCode,
  } = window;

  const pageWhitelist = ["product", "cart"];

  // Disable if not on a page we need the messaging
  if (!pageWhitelist.some((page) => page == pageType)) return;

  // Disable when changing from the default language as PayPal later messaging doesn't support this for now
  if (defaultLang !== currentLang) return;

  // Disable if missing clientId, PayPal not available in country or not a valid PayPal Pay Later country
  if (
    !payPalClientId ||
    !payPalPaymentMethodAvailable ||
    !payPalPayLaterMessagingAvailable
  )
    return;

  const script = document.createElement("script");
  script.src = `https://www.paypal.com/sdk/js?client-id=${payPalClientId}&components=messages&currency=${currentCurrencyCode}`;
  script.async = 1;

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(script);

  const element = document.querySelector(".paypal-message");
  element && element.classList.remove("d-none");
};
