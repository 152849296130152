import { jsx as _jsx, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { i18n } from "../i18n";
import { MainMenu } from "./MainMenu";
import { focusFirstSideMenuItem, updateSideMenuBody } from "./SideMenu";
import { CategoryMenuItems } from "./CategoryMenuItems";
import { select } from "@fruugo/utilities";
function getMenuData() {
    var data = sessionStorage.getItem("preloadedNavJson");
    if (data) {
        return JSON.parse(data);
    }
    else {
        return null;
    }
}
var config = {
    categoryMenuClass: ".js-category-menu",
};
export var CategoryMenu = function (_a) {
    var currentItem = _a.currentItem, handleBack = _a.handleBack;
    var menuData = getMenuData();
    var backToMainMenu = function () { return updateSideMenuBody(_jsx(MainMenu, {})); };
    var parentItem = menuData && currentItem && findParentById(menuData.items, currentItem.id);
    var updateMenu = function (item) {
        var categoryMenu = select(config.categoryMenuClass);
        var handleBack = function () {
            var parentItem = menuData && findParentById(menuData.items, item.id);
            if (parentItem) {
                updateMenu(parentItem);
                focusFirstSideMenuItem();
            }
            else {
                updateSideMenuBody(_jsx(CategoryMenu, {}));
            }
        };
        if (item) {
            categoryMenu === null || categoryMenu === void 0 ? void 0 : categoryMenu.replaceChildren(_jsx(CategoryMenu, { currentItem: item, handleBack: handleBack }));
            focusFirstSideMenuItem();
        }
        else {
            updateSideMenuBody(_jsx(CategoryMenu, {}));
        }
    };
    var items = currentItem ? currentItem.items : menuData === null || menuData === void 0 ? void 0 : menuData.items;
    var title = currentItem
        ? currentItem.link.text
        : i18n("common.departments");
    var backToText = getBackToText(currentItem, parentItem);
    handleBack = handleBack !== null && handleBack !== void 0 ? handleBack : backToMainMenu;
    return (_jsxs("div", { class: "js-category-menu", children: [_jsxs("button", { type: "button", onClick: handleBack, class: "side-menu-item back-button", children: [_jsx("svg", { class: "icon", width: "20", height: "20", children: _jsx("use", { xlinkHref: "/marketplace/images/sprite-icon.svg#chevron-left" }) }), _jsx("span", { children: i18n("menu.back.to", backToText) })] }), _jsxs("section", { children: [_jsx("h4", { class: "overflow-ellipsis px-2", children: title }), items && _jsx(CategoryMenuItems, { items: items, updateMenu: updateMenu })] })] }));
};
function getBackToText(currentItem, parentItem) {
    if (parentItem)
        return parentItem.link.text;
    if (currentItem)
        return i18n("common.departments");
    return i18n("menu.main.menu");
}
function findParentById(items, id) {
    var parent = null;
    function search(items) {
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            if (item.items && item.items.some(function (subItem) { return subItem.id === id; })) {
                parent = item;
                return true;
            }
            if (item.items && item.items.length > 0) {
                if (search(item.items)) {
                    return true;
                }
            }
        }
        return false;
    }
    search(items);
    return parent;
}
