import environments from "../types/environments";

export function initKlarnaOnsiteMessaging() {
  const {
    environment,
    klarnaPaymentMethodAvailable,
    klarnaRegion,
    klarnaOnSiteMessagingClientId,
  } = window;

  if (!klarnaPaymentMethodAvailable) return;

  let scriptSrc;

  if (environment === environments.production) {
    scriptSrc =
      "https://" + klarnaRegion + "-library.klarnaservices.com/lib.js";
  } else {
    scriptSrc =
      "https://" +
      klarnaRegion +
      "-library.playground.klarnaservices.com/lib.js";
  }

  const script = document.createElement("script");
  script.src = scriptSrc;
  script.setAttribute("data-client-id", klarnaOnSiteMessagingClientId);
  script.async = 1;

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(script);

  const element = document.querySelector("klarna-placement");
  element && element.classList.remove("d-none");
}
