import { select, throttle } from "@fruugo/utilities";
import { DeviceWidth } from "../types";
import { minWidth } from "./utilities";
var pageBlackList = ["help", "account"];
var lastScrollTop = 0;
export function initStickyHeader() {
    var pageType = window.pageType;
    if (pageBlackList.some(function (p) { return p === pageType; }))
        return;
    minWidth(DeviceWidth.Medium, function () { return window.removeEventListener("scroll", throttledStickyHeader); }, function () { return window.addEventListener("scroll", throttledStickyHeader); });
}
var throttledStickyHeader = throttle(stickyHeader, 250);
function stickyHeader() {
    var currentScrollTop = document.documentElement.scrollTop;
    var header = select(".header");
    var buffer = 200;
    var stickyCssClass = "sticky";
    if (currentScrollTop > lastScrollTop) {
        header === null || header === void 0 ? void 0 : header.classList.remove(stickyCssClass);
    }
    else if (currentScrollTop < lastScrollTop - buffer) {
        if (!(header === null || header === void 0 ? void 0 : header.classList.contains(stickyCssClass))) {
            header === null || header === void 0 ? void 0 : header.classList.add(stickyCssClass);
        }
    }
    lastScrollTop = currentScrollTop;
}
