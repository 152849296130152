/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

 */

import { i18n } from "./i18n.js";
const { currentCountry } = window;

export default function initDepartments() {
  window.addEventListener(
    "navigationReady",
    () => {
      const data = getDataForDepartments();
      const departments = formatDataForDepartments(data);
      const grid = document.getElementById("departments-grid");
      updateDepartmentsGrid(departments, grid);
    },
    false
  );
}

/**
 *
 * @returns {object}
 */
function getDataForDepartments() {
  return JSON.parse(sessionStorage.getItem("preloadedNavJson"));
}

/**
 *
 * @param data
 * @returns {[]}
 */
function formatDataForDepartments(data) {
  const departments = [];
  let i = 0;
  while (i < 12) {
    const department = formatDataForDepartment(data.items[i]);
    departments.push(department);
    i++;
  }
  return departments;
}

/**
 *
 * @param dataItem
 * @returns {{subtitle: {}, id: string, href: string, title: string}}
 */
function formatDataForDepartment(dataItem) {
  let subcategory1 = dataItem.items[0].link.text;
  let subcategory2 = dataItem.items[1].link.text;
  subcategory1 = subcategory1 ? subcategory1.toLowerCase() : "";
  subcategory2 = subcategory2 ? subcategory2.toLowerCase() : "";
  const subtitle = {
    short: i18n("home.departments.grid.subtitle", [subcategory1, subcategory2]),
    long: i18n("home.departments.hero.subtitle", [
      subcategory1,
      subcategory2,
      currentCountry,
    ]),
  };
  return {
    id: dataItem.id,
    href: dataItem.link.href,
    title: dataItem.link.text,
    subtitle: subtitle,
  };
}

/**
 *
 * @param departments
 * @param target
 */
function updateDepartmentsGrid(departments, target) {
  let i = 0;
  for (const child in target.children) {
    if (Object.hasOwnProperty.call(target.children, child)) {
      const element = target.children[child];
      const department = departments[i];
      updateDepartmentsGridSingle(department, element);
    }
    i++;
  }
}

function updateDepartmentsGridSingle(department, element) {
  const picture = document.createElement("picture");
  const source1 = document.createElement("source");
  source1.srcset = `/marketplace/images/processed/grid_${department.id}-300.webp 350w`;
  source1.type = "image/webp";
  picture.appendChild(source1);

  const source2 = document.createElement("source");
  source2.srcset = `/marketplace/images/processed/grid_${department.id}-300.jpg 350w`;
  source2.type = "image/jpg";
  picture.appendChild(source2);

  const image = new Image();
  image.src = `/marketplace/images/home/grid_${department.id}.jpg`;
  image.alt = department.title;
  image.width = 100;
  image.height = 100;
  image.className = "item-grid-image";
  image.ariaHidden = true;
  picture.appendChild(image);

  const shopNowHover = document.createElement("p");
  shopNowHover.className = "item-grid-hover m-0";
  shopNowHover.innerHTML = i18n("home.departments.shop.now");

  const imgDiv = document.createElement("div");
  imgDiv.className = "item-grid-circle mb-8";
  imgDiv.appendChild(picture);
  imgDiv.appendChild(shopNowHover);

  element.querySelector(".item-grid-placeholder").remove();
  element.prepend(imgDiv);

  element.querySelector("h2").innerHTML = department.title;
  element.setAttribute("href", department.href);
}
