var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getUserConsent, defaultConsents } from "@fruugo/utilities";
import { isMobileDevice } from "../utilities";
import { detect } from "detect-browser";
import { OnSiteAttribution, } from "../../types";
import { isInABTest } from "../../modules/abTesting.js";
var pageType = window.pageType;
var browser = detect();
var defaultData = {
    eventName: pageType !== null && pageType !== void 0 ? pageType : "",
    platform: isMobileDevice() ? "mobile_web" : "desktop_web",
    browserName: browser ? browser.name : "",
    browserVersion: browser ? browser.version : "",
};
function getOnSiteAttribution() {
    return isInABTest("SHOP-858-BPCarousels", "B")
        ? OnSiteAttribution.BYTEPLUS
        : OnSiteAttribution.FRUUGO;
}
export var sendFruugoAnalyticsPageViewEvent = function (item) {
    var url = "/event/log/page-view";
    var data = buildItemEventData(item);
    sendFruugoAnalyticsEvent(url, data);
};
export var sendFruugoAnalyticsClickEvent = function (item) {
    var url = "/event/log/click";
    var data = buildItemEventData(item);
    sendFruugoAnalyticsEvent(url, data);
};
export var sendFruugoAnalyticsImpressionEvent = function (items) {
    var url = "/event/log/impression";
    var data = __assign(__assign({}, defaultData), { items: items.map(function (item) { return buildItemEventData(item); }), onSiteAttribution: getOnSiteAttribution() });
    sendFruugoAnalyticsEvent(url, data);
};
export var sendFruugoAnalyticsAddToCartEvent = function (productId) {
    var url = "/event/log/add-to-cart";
    var data = __assign(__assign({}, defaultData), { productId: productId, onSiteAttribution: getOnSiteAttribution() });
    sendFruugoAnalyticsEvent(url, data);
};
export var sendFruugoAnalyticsBuyNowEvent = function (productId) {
    var url = "/event/log/buy-now";
    var data = __assign(__assign({}, defaultData), { productId: productId, onSiteAttribution: getOnSiteAttribution() });
    sendFruugoAnalyticsEvent(url, data);
};
export var sendFruugoAnalyticsRemoveItemEvent = function (productId) {
    var url = "/event/log/remove-item";
    var data = __assign(__assign({}, defaultData), { productId: productId, onSiteAttribution: getOnSiteAttribution() });
    sendFruugoAnalyticsEvent(url, data);
};
export var sendFruugoAnalyticsSearchEvent = function (query) {
    var url = "/event/log/search";
    var data = __assign(__assign({}, defaultData), { searchQuery: encodeURIComponent(query), onSiteAttribution: getOnSiteAttribution() });
    sendFruugoAnalyticsEvent(url, data);
};
var sendFruugoAnalyticsEvent = function (url, data) {
    var consents = getUserConsent() || defaultConsents;
    if (consents.analytics) {
        navigator.sendBeacon(url, JSON.stringify(data));
    }
};
var getParentProductId = function () {
    return pageType && pageType == "product" ? window.skuInfo.productId : "";
};
var getCarouselName = function (listName) {
    if (!listName)
        return pageType !== null && pageType !== void 0 ? pageType : "";
    if (listName.includes("search"))
        return pageType !== null && pageType !== void 0 ? pageType : "";
    var parts = listName.split(".");
    if (parts.length <= 1)
        return pageType !== null && pageType !== void 0 ? pageType : "";
    return "".concat(pageType, "_carousel_").concat(parts[1]);
};
var getCarouselPosition = function (listName) {
    if (!listName)
        return null;
    var parts = listName.split(".");
    if (parts.length < 2)
        return null;
    return parts[1];
};
var buildItemEventData = function (item) {
    var _a;
    var carouselName = getCarouselName(item.list_name);
    var data = __assign(__assign({}, defaultData), { productId: item.id, eventName: carouselName !== null && carouselName !== void 0 ? carouselName : pageType, premiumRetailer: (_a = window.isPremiumRetailer) !== null && _a !== void 0 ? _a : false, onSiteAttribution: getOnSiteAttribution() });
    var parentProductId = getParentProductId();
    if (parentProductId)
        data.parentProductId = parentProductId;
    if (item.isCarousel) {
        data.carouselPosition = getCarouselPosition(item.list_name);
    }
    return data;
};
