import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
export var CategoryMenuItems = function (_a) {
    var items = _a.items, updateMenu = _a.updateMenu;
    var withChildren = function (item) { return (_jsx("li", { children: _jsxs("button", { onClick: function () { return updateMenu(item); }, class: "side-menu-item has-children", children: [_jsx("span", { children: item.link.text }), _jsx("svg", { class: "icon", width: "20", height: "20", children: _jsx("use", { xlinkHref: "/marketplace/images/sprite-icon.svg#chevron-right" }) })] }) })); };
    var withoutChildren = function (item) { return (_jsx("li", { children: _jsx("a", { href: item.link.href, class: "side-menu-item", children: item.link.text }) })); };
    return (_jsx(_Fragment, { children: items && items.length > 0 && (_jsx("ul", { class: "menu-list", children: items.map(function (item) {
                if (item.items && item.items.length > 0) {
                    return withChildren(item);
                }
                else {
                    return withoutChildren(item);
                }
            }) })) }));
};
