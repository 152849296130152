import { jsxs as _jsxs, jsx as _jsx } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { i18n } from "./i18n.js";
import { select, selectAll } from "@fruugo/utilities";
var ReadMoreState;
(function (ReadMoreState) {
    ReadMoreState[ReadMoreState["Open"] = 0] = "Open";
    ReadMoreState[ReadMoreState["Closed"] = 1] = "Closed";
})(ReadMoreState || (ReadMoreState = {}));
/**
 * @description Similar to read-more, but it works on list items rather than text
 */
var ReadMoreList = /** @class */ (function () {
    function ReadMoreList(selector) {
        var _a, _b;
        this.visibleItems = 1;
        this.state = ReadMoreState.Closed;
        this.items = [];
        this.readMoreButtonClass = "js-read-more-list-button";
        this.container = (_a = select(selector)) !== null && _a !== void 0 ? _a : null;
        if (!this.container)
            return;
        // Only enable for Mobile for now. Add device prop later
        if (!window.matchMedia("(max-width: 768px)").matches)
            return;
        var itemsClass = this.container.dataset.itemClass;
        if (!itemsClass)
            return;
        var items = (_b = selectAll(itemsClass)) !== null && _b !== void 0 ? _b : [];
        this.items = Array.from(items);
    }
    ReadMoreList.prototype.toggleItems = function () {
        var _this = this;
        this.items &&
            this.items.forEach(function (item, i) {
                // For the initially visible items
                if (i < _this.visibleItems) {
                    _this.state === ReadMoreState.Closed
                        ? item.classList.add("line-clamp")
                        : item.classList.remove("line-clamp");
                }
                // For the other items
                if (i >= _this.visibleItems)
                    item.classList.toggle("d-none");
            });
    };
    ReadMoreList.prototype.init = function () {
        this.toggleItems();
        this.insertButton();
    };
    ReadMoreList.prototype.insertButton = function () {
        if (!this.items || this.items.length < 2)
            return;
        this.items[this.items.length - 1].insertAdjacentElement("afterend", this.getButton());
    };
    ReadMoreList.prototype.getButton = function () {
        var _this = this;
        var text = this.state === ReadMoreState.Closed
            ? i18n("generic.show-more")
            : i18n("generic.show-less");
        var icon = this.state === ReadMoreState.Closed
            ? this.getIcon("chevron-down")
            : this.getIcon("chevron-up");
        return (_jsxs("button", { class: "ReadMore d-flex ".concat(this.readMoreButtonClass), onClick: function () { return _this.handleClick(); }, children: [text, " ", icon] }));
    };
    ReadMoreList.prototype.removeButtons = function () {
        var _a;
        (_a = select(".".concat(this.readMoreButtonClass))) === null || _a === void 0 ? void 0 : _a.remove();
    };
    ReadMoreList.prototype.toggleButtons = function () {
        this.removeButtons();
        this.insertButton();
    };
    ReadMoreList.prototype.getIcon = function (iconName) {
        return (_jsx("svg", { class: "icon", style: "fill:#000;", children: _jsx("use", { href: "/marketplace/images/sprite-icon.svg#".concat(iconName) }) }));
    };
    ReadMoreList.prototype.handleClick = function () {
        // Invert state
        this.state =
            this.state === ReadMoreState.Closed
                ? ReadMoreState.Open
                : ReadMoreState.Closed;
        this.toggleItems();
        this.toggleButtons();
    };
    return ReadMoreList;
}());
export { ReadMoreList };
