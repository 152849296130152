import xhr from "./xhr.js";

function getPaymentMethodsList(forceFetch) {
  return new Promise((resolve) => {
    const key = "paymentMethods_" + window.currentCountry;
    const cached = sessionStorage.getItem(key);
    if (cached === null || forceFetch) {
      xhr(`/marketplace/api/payment-methods`).then(({ response }) => {
        sessionStorage.setItem(key, response);
        resolve(JSON.parse(response));
      });
    } else {
      resolve(JSON.parse(cached));
    }
  });
}

function renderIcons(list) {
  const container = document.createElement("div");
  container.className = "icon-pay-list";
  list.forEach((name) => {
    const cssName = name.replace(/\s+/g, "").toLowerCase();
    const icon = document.createElement("i");
    icon.className = `icon icon-pay icon-pay--${cssName}`;
    container.appendChild(icon);
  });
  return container;
}

function injectElementIntoDOM(element, targets) {
  targets.forEach((target) => {
    target.innerHTML = "";
    target.appendChild(element.cloneNode(true));
  });
}

// grab the first set of payment icons on the page and return a copy as a string
// used to render icons inside modals rendered after page load
export function clonePaymentIcons() {
  const existingIcons = document.querySelector(".icon-pay-list");
  if (existingIcons === null) return;
  return existingIcons.outerHTML;
}

export function initPaymentIcons(
  selector = ".js-payment-icons",
  forceFetch = false
) {
  const elements = document.querySelectorAll(selector);
  if (elements === null) return;
  getPaymentMethodsList(forceFetch)
    .then(renderIcons)
    .then((icons) => injectElementIntoDOM(icons, [...elements]));
}
