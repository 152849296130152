var onSiteAttribution = window.onSiteAttribution;
export var addItemToCartRequest = function (skuId, quantity) {
    var endpoint = "/marketplace/api/cart/items?skuId=".concat(skuId, "&quantity=").concat(quantity, "&language=").concat(window.currentLang);
    if (onSiteAttribution)
        endpoint += "&source=".concat(onSiteAttribution);
    return fetch(endpoint, {
        method: "POST",
    }).then(function (res) { return res.json(); });
};
export var buyNowRequest = function (skuId, quantity) {
    var endpoint = "/marketplace/api/cart/items/buy-now?skuId=".concat(skuId, "&language=").concat(window.currentLang, "&quantity=").concat(quantity);
    if (onSiteAttribution)
        endpoint += "&source=".concat(onSiteAttribution);
    return fetch(endpoint, {
        method: "POST",
    }).then(function (res) { return res.json(); });
};
export var removeItemFromCartRequest = function (skuId, quantity) {
    return fetch("/marketplace/api/cart/items/remove/".concat(skuId, "?quantity=").concat(quantity, "&language=").concat(window.currentLang), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    }).then(function (res) { return res.json(); });
};
export var updateCartItemQuantityRequest = function (skuId, quantity) {
    return fetch("/marketplace/api/cart/items/".concat(skuId, "?quantity=").concat(quantity, "&language=").concat(window.currentLang), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    }).then(function (res) { return res.json(); });
};
export var getSearchRecommendations = function (query) {
    return fetch("/marketplace/search/category?q=".concat(encodeURIComponent(query), "&language=").concat(window.currentLang)).then(function (res) { return res.json(); });
};
export var getSafetyAttributes = function (skuId, language) {
    return fetch("/marketplace/api/sku/".concat(skuId, "/safety-attributes/").concat(window.currentCountry, "/").concat(language)).then(function (res) { return res.json(); });
};
export var getTranslatedSafetyAttributes = function (productId, skuId) {
    return fetch("/marketplace/api/translation/skuSafetyAttributes/".concat(productId, "/").concat(skuId, "?language=").concat(window.currentLang)).then(function (res) { return res.json(); });
};
