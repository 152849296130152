var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { Logger, select, selectAll } from "@fruugo/utilities";
import initReadMore from "./read-more.js";
import { i18n } from "./i18n.js";
import { sendGoogleAnalyticsEvent } from "./analytics/google-analytics";
import { getSafetyAttributes, getTranslatedSafetyAttributes } from "./api";
var Log = new Logger(window.environment);
var machineTranslationRequirements = window.machineTranslationRequirements, skuId = window.skuId, productId = window.productId, currentLang = window.currentLang, descriptionBeforeTranslation = window.descriptionBeforeTranslation, skuInfo = window.skuInfo;
var originalDescriptionToggled = false;
export function initProductTranslations() {
    // On-the-fly machine translations
    if (machineTranslationRequirements.title) {
        translateProductTitle();
    }
    if (machineTranslationRequirements.description) {
        translateProductVariants();
        translateProductDescription();
    }
    if (machineTranslationRequirements.safetyAttributes) {
        storeInitialSafetyAttributes();
        getTranslatedSafetyAttributes(productId, skuId)
            .then(function (data) {
            sessionStorage.setItem("translatedSafetyAttributes", JSON.stringify(data.attributes));
            updateSafetyAttributes(data.attributes);
        })
            .catch(function (error) { return Log.error(error); });
    }
    // If we need to machine translate the product description or safety attributes on-the-fly, or if they have already been machine translated and saved to Solr,
    // add the "This description has been machine translated. View original version" section
    if (machineTranslationRequirements.description ||
        machineTranslationRequirements.safetyAttributes ||
        descriptionBeforeTranslation.enabled) {
        initViewOriginalDescription();
    }
}
function storeInitialSafetyAttributes() {
    var attributes = selectAll(".js-safety-attributes .attribute-list");
    var initialAttributes = {};
    attributes.forEach(function (attribute) {
        var _a;
        var title = attribute.querySelector("[data-safety-attribute-title]");
        var values = attribute.querySelectorAll("[data-safety-attribute-value]");
        if (title && values) {
            var id = title.dataset.safetyAttributeTitle;
            if (id) {
                initialAttributes[id] = {
                    title: (_a = title.textContent) !== null && _a !== void 0 ? _a : "",
                    values: Array.from(values).map(function (value) { var _a; return (_a = value.textContent) !== null && _a !== void 0 ? _a : ""; }),
                };
            }
        }
    });
    sessionStorage.setItem("initialSafetyAttributes", JSON.stringify(initialAttributes));
}
function updateSafetyAttributes(data) {
    Object.keys(data).forEach(function (key) {
        var titleElement = select(".js-safety-attribute-title[data-safety-attribute-title=\"".concat(key, "\"]"));
        var valueSpans = selectAll("span[data-safety-attribute-value=\"".concat(key, "\"]"));
        if (!data[key])
            return;
        if (titleElement) {
            titleElement.textContent = data[key].title;
        }
        valueSpans &&
            valueSpans.forEach(function (span, i) {
                span.textContent = data[key].values[i];
            });
    });
}
function translateProductDescription() {
    fetch("/marketplace/api/translation/skuDescriptionAndDisplayAttributes/".concat(skuId, "?language=").concat(currentLang))
        .then(function (res) { return res.json(); })
        .then(function (data) {
        var _a, _b;
        var container = select(".js-product-description");
        if (container) {
            sessionStorage.setItem("originalDescription", (_a = container.textContent) !== null && _a !== void 0 ? _a : "");
            sessionStorage.setItem("machineTranslatedDescription", (_b = data.description) !== null && _b !== void 0 ? _b : "");
            container.textContent = data.description;
        }
        // Re-init read more on the product description after adding in the translated version
        initReadMore();
    })
        .catch(function (error) {
        Log.error(error);
    });
}
function translateProductVariants() {
    fetch("/marketplace/api/translation/skuVariantAttributes/".concat(productId, "/").concat(skuId, "?language=").concat(currentLang))
        .then(function (res) { return res.json(); })
        .then(function (variantsData) {
        Object.keys(variantsData).forEach(function (key) {
            var attributeContainer = select(".form-group[data-attribute_id=\"".concat(key, "\"]"));
            Object.keys(variantsData[key].valueTranslationsMap).forEach(function (mapKey) {
                // Product Variant Options
                attributeContainer &&
                    attributeContainer
                        .querySelectorAll("option")
                        .forEach(function (element) {
                        if (element.textContent === mapKey) {
                            element.textContent =
                                variantsData[key].valueTranslationsMap[mapKey];
                        }
                    });
            });
        });
    })
        .catch(function (error) {
        Log.error(error);
    });
}
function translateProductTitle() {
    fetch("/marketplace/api/translation/skuTitle/".concat(skuId, "?language=").concat(currentLang))
        .then(function (res) { return res.json(); })
        .then(function (data) {
        var _a, _b;
        var titleName = data.name.trim();
        var titleSeparatorIndex = document.title.lastIndexOf("|");
        if (titleSeparatorIndex > 0) {
            var productTitle = document.title
                .substring(0, titleSeparatorIndex)
                .trim();
            if (productTitle !== titleName) {
                document.title =
                    titleName + document.title.substring(titleSeparatorIndex - 1);
            }
        }
        (_a = select('meta[property="og:title"]')) === null || _a === void 0 ? void 0 : _a.setAttribute("content", titleName);
        (_b = select(".js-product-title")) === null || _b === void 0 ? void 0 : _b.setAttribute("title", titleName);
    })
        .catch(function (error) {
        Log.error(error);
    });
}
function handleOriginalDescriptionToggle(event) {
    updateLink(event);
    toggleDescription(originalDescriptionToggled);
    if (select(".js-safety-attributes")) {
        toggleSafetyAttributes(originalDescriptionToggled);
    }
    sendOriginalDescriptionGAEvent(originalDescriptionToggled);
    originalDescriptionToggled = !originalDescriptionToggled;
}
function updateLink(event) {
    var button = event.target;
    button.textContent = originalDescriptionToggled
        ? i18n("product.description.view-original")
        : i18n("product.description.view");
}
function toggleSafetyAttributes(toggled) {
    return __awaiter(this, void 0, void 0, function () {
        var storedAttributes, attributes, language;
        return __generator(this, function (_a) {
            // If we did the on-the-fly translations, use the session storage
            if (machineTranslationRequirements.safetyAttributes) {
                storedAttributes = void 0;
                if (toggled) {
                    storedAttributes = sessionStorage.getItem("translatedSafetyAttributes");
                }
                else {
                    storedAttributes = sessionStorage.getItem("initialSafetyAttributes");
                }
                if (!storedAttributes)
                    return [2 /*return*/];
                attributes = JSON.parse(storedAttributes);
                updateSafetyAttributes(attributes);
            }
            else {
                language = toggled
                    ? currentLang
                    : descriptionBeforeTranslation.language;
                getSafetyAttributes(skuId, language)
                    .then(function (data) { return updateSafetyAttributes(data.attributes); })
                    .catch(function (error) { return Log.error(error); });
            }
            return [2 /*return*/];
        });
    });
}
function toggleDescription(toggled) {
    var container = select(".js-product-description");
    if (!container)
        return;
    // If we did the on-the-fly translations, we'll have the descriptions stored in sessionStorage
    if (machineTranslationRequirements.description) {
        var storedDescription = void 0;
        if (toggled) {
            storedDescription = sessionStorage.getItem("machineTranslatedDescription");
        }
        else {
            storedDescription = sessionStorage.getItem("originalDescription");
        }
        if (storedDescription)
            container.textContent = storedDescription;
    }
    else {
        // otherwise get it from the SkuInfo
        if (toggled) {
            container.textContent = skuInfo.description;
        }
        else {
            container.textContent = skuInfo.descriptionBeforeTranslation;
        }
    }
}
function initViewOriginalDescription() {
    var container = select(".js-product-description-container");
    var description = select(".js-product-description");
    description &&
        (container === null || container === void 0 ? void 0 : container.insertBefore(viewOriginalDescriptionTemplate(), description));
}
var viewOriginalDescriptionTemplate = function () { return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [_jsx("span", { children: i18n("product.description.translated", descriptionBeforeTranslation.languageText) }), " ", _jsx("button", { class: "js-view-original-description link-button", onClick: handleOriginalDescriptionToggle, children: i18n("product.description.view-original") })] }), _jsx("hr", {})] })); };
function sendOriginalDescriptionGAEvent(toggled) {
    if (!toggled) {
        sendGoogleAnalyticsEvent("custom_event", "pdp-clicked-original-description-link", descriptionBeforeTranslation.language);
    }
    else {
        sendGoogleAnalyticsEvent("custom_event", "pdp-clicked-machine-translated-description-link", descriptionBeforeTranslation.language);
    }
}
