import { jsx as _jsx } from "jsx-dom/jsx-runtime";
/** @jsxImportSource jsx-dom */
import { select } from "@fruugo/utilities";
import { Banner } from "./components/Banner";
export function initSwissTaxBanner() {
    var bannerMount = "#swissTaxBannerMount";
    var element = select(bannerMount);
    if (element && !window.sessionStorage.getItem("closed_swiss_tax_banner")) {
        element.replaceChildren(_jsx(Banner, {}));
    }
}
