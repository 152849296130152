import { Logger } from "@fruugo/utilities";

const Log = new Logger(window.environment);

export const toggleClass = (element, className) => {
  if (!element) return;
  if (element.classList.contains(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
};

export const getKeyboardFocusableElements = (element = document) => {
  const selector =
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

  const elements = element.querySelectorAll(selector);

  if (elements && elements.length > 0) {
    return [...element.querySelectorAll(selector)].filter(
      (el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")
    );
  } else {
    return [];
  }
};

export const disableBodyScroll = () => {
  const currentScroll = document.documentElement.scrollTop;
  document.body.style.top = -currentScroll + "px";
  document.body.classList.add("no-scroll");
};

export const enableBodyScroll = () => {
  const previousScroll = Math.abs(
    Number(document.body.style.top.replace("px", ""))
  );
  delete document.body.style.top;
  document.body.classList.remove("no-scroll");
  document.documentElement.scrollTop = previousScroll;
};

export const slideUp = (target, duration = 300) => {
  target.style.maxHeight = target.scrollHeight + "px";
  target.style.maxHeight = "0";
  target.style.display = "none";

  window.setTimeout(function () {
    target.classList.remove("is-visible");
  }, duration);
};

export const slideDown = (target) => {
  if (!target) return;
  var getHeight = function () {
    target.style.display = "block";
    var height = target.scrollHeight + "px";
    target.style.display = "";
    return height;
  };

  var height = getHeight();
  target.classList.add("is-visible");
  target.style.maxHeight = height;
};

export const slideToggle = (target, duration = 300) => {
  if (!target) return;
  if (!target.classList.contains("is-visible")) {
    return slideDown(target);
  } else {
    return slideUp(target, duration);
  }
};

export function stringToHtml(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  const html = doc.body.children;
  if (html.length > 1) {
    Log.warning(`stringToHtml only returns single children. str: ${str}`);
  }
  return html[0];
}

export function minWidth(width, minWidthFn, maxWidthFn) {
  let matchesCheck = null;

  function runCallbacks() {
    const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);
    const { matches } = mediaQuery;

    // Only run this when matches changes rather than on every resize event
    if (matchesCheck === null || matchesCheck != matches) {
      if (matches) {
        minWidthFn && minWidthFn();
      } else {
        maxWidthFn && maxWidthFn();
      }
    }

    matchesCheck = matches;
  }

  runCallbacks();
  window.addEventListener("resize", runCallbacks, false);
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
