/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

 */

/**
 *
 * @param url
 * @param method
 * @returns {Promise<XMLHttpRequest>}
 */
export default function xhr(url, method = "GET", headers = {}) {
  const request = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    request.onload = () => {
      if (request.readyState !== 4) {
        return;
      }
      if (request.status >= 200 && request.status < 300) {
        resolve(request);
      } else {
        reject(
          new Error(
            `status: ${request.status}\nstatusText: ${request.statusText}\nresponseText: ${request.responseText}`
          )
        );
      }
    };
    request.open(method, url, true);
    for (const [key, value] of Object.entries(headers)) {
      request.setRequestHeader(key, value);
    }
    request.send();
  });
}

export function xhrCustom(url, method, contentType, data) {
  const request = new XMLHttpRequest();
  request.onreadystatechange = function () {
    // listen for state changes
    if (request.readyState == 4 && request.status == 200) {
      // when completed we can move away
      window.location.replace(request.responseURL);
    }
  };
  request.open(method || "GET", url);
  request.setRequestHeader(
    "Content-Type",
    contentType || "text/plain;charset=UTF-8"
  );
  request.send(data);
}
