import { OnSiteAttribution, } from "../../types";
import environments from "../../types/environments.js";
import { Logger } from "@fruugo/utilities";
var Log = new Logger(window.environment);
export function sendGoogleAnalyticsEvent(eventName, event_category, event_action, event_label) {
    if (event_label === void 0) { event_label = window.currentCountry.toLowerCase(); }
    gtag("event", eventName, { event_category: event_category, event_action: event_action, event_label: event_label });
}
export function createEcommerceProductPayload(productData, location) {
    return productData.map(function (_a, index) {
        var _b;
        var quantity = _a.quantity, sku = _a.sku;
        var payload = {
            id: sku.productId,
            name: sku.title,
            brand: sku.brand ? sku.brand.title : undefined,
            quantity: quantity,
            price: sku.price ? sku.price.eurValueForAnalytics : undefined,
            google_business_vertical: "retail",
            source: (_b = window.onSiteAttribution) !== null && _b !== void 0 ? _b : OnSiteAttribution.FRUUGO,
        };
        if (location) {
            payload.list_name = location;
            payload.list_position = index;
        }
        return payload;
    });
}
/**
 * Log an Event for the Google Recommendation AI (RAI)
 * Global script in analytics.htm sets the required settings such as the API & Project keys
 * @param {{}} userEvent The event to track as specified: https://cloud.google.com/recommendations-ai/docs/record-events?hl=he#pixel
 * @see https://cloud.google.com/retail/docs/record-events
 * @returns
 */
export function logGoogleRecommendationsAIEvent(userEvent) {
    if (!userEvent)
        return;
    if (window.environment !== environments.production ||
        window.currentCountry !== "GB")
        return;
    try {
        if (!window.GAKey)
            throw new Error("Error logging Google RAI: GA key not found.");
        // Get the visitor/clientId from GA
        gtag("get", window.GAKey, "client_id", function (clientId) {
            if (!clientId)
                return;
            userEvent.visitorId = clientId;
            sendDataToGoogleRecommendationAI(userEvent);
        });
    }
    catch (error) {
        Log.error(error);
    }
}
export function sendDataToGoogleRecommendationAI(userEvent) {
    var _a;
    if (!userEvent)
        return;
    if (!window.googleRAISettings)
        throw new Error("Error sending Google RAI data: settings not found.");
    var _gre = window._gre || [];
    _gre.push(["apiKey", window.googleRAISettings.apiKey]);
    _gre.push(["projectId", window.googleRAISettings.projectId]);
    _gre.push(["locationId", window.googleRAISettings.locationId]);
    _gre.push(["catalogId", window.googleRAISettings.catalogId]);
    _gre.push(["logEvent", userEvent]);
    window._gre = _gre;
    var gre = document.createElement("script");
    gre.type = "text/javascript";
    gre.async = true;
    gre.src = "https://www.gstatic.com/retail/v2_event.js";
    var s = document.getElementsByTagName("script")[0];
    (_a = s.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(gre, s);
}
