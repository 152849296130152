import { jsx as _jsx, jsxs as _jsxs } from "jsx-dom/jsx-runtime";
export var SearchRecommendations = function (_a) {
    var query = _a.query, recommendations = _a.recommendations;
    function getIcon(type) {
        var iconUrl = "/marketplace/images/sprite-icon.svg?v=2";
        return type == "webshop" ? "".concat(iconUrl, "#category") : "".concat(iconUrl, "#sell");
    }
    function boldifySearchQuery(name) {
        var regex = new RegExp("(".concat(query, ")"), "gi");
        return name.replace(regex, "<strong>$1</strong>");
    }
    function getUrl(link) {
        try {
            var _a = window.location, protocol = _a.protocol, hostname = _a.hostname, port = _a.port;
            var baseUrl = "".concat(protocol, "//").concat(hostname).concat(port ? ":".concat(port) : "");
            var url = new URL(baseUrl + link);
            url.searchParams.set("q", query);
            return url.toString();
        }
        catch (err) {
            return link;
        }
    }
    return recommendations.map(function (recommendation) { return (_jsx("li", { children: _jsxs("a", { href: getUrl(recommendation.link), children: [_jsx("svg", { class: "icon", width: "20", height: "20", "aria-hidden": "true", children: _jsx("use", { href: getIcon(recommendation.type) }) }), _jsx("span", { dangerouslySetInnerHTML: {
                        __html: boldifySearchQuery(recommendation.name),
                    } })] }) })); });
};
