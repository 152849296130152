/*
     __  __            _        _         _
    |  \/  | __ _ _ __| | _____| |_ _ __ | | __ _  ___ ___
    | |\/| |/ _` | '__| |/ / _ \ __| '_ \| |/ _` |/ __/ _ \
    | |  | | (_| | |  |   <  __/ |_| |_) | | (_| | (_|  __/
    |_|  |_|\__,_|_|  |_|\_\___|\__| .__/|_|\__,_|\___\___|
                                   |_|

 */

/**
 * Converts underscores in name to hyphens:
 * "CXO2-224_Delivery_info" DB values gets converted to "cxo2-224-delivery-info"
 */
const getCurrentABTests = () => {
  return document.cookie
    .split("; ")
    .filter((item) => item.indexOf("abt_") === 0)
    .map((test) => {
      var id = test.split("_")[1];
      var parts = test.toLowerCase().replace("=", "%%").split("%%");
      var name = parts[0].split("_").slice(2).join("-");

      var value = parts[1].split("_");
      var segment = value[0];
      var dimension = value[1];

      return {
        id,
        name,
        segment,
        dimension,
      };
    });
};

/**
 * @param {string} name
 * @param {string} segment
 *
 * Converts underscores in name to hyphens:
 * "CXO2-224_Delivery_info" DB values gets converted to "cxo2-224-delivery-info"
 */
export const isInABTest = (name, segment) => {
  if (name === undefined) return false;

  const tests = getCurrentABTests();

  // support legacy test naming convention
  name = name.replace(/^abt_[0-9]+_/, "");

  if (segment) {
    return tests.some(
      (test) =>
        test.name === name.toLowerCase() &&
        test.segment === segment.toLowerCase()
    );
  }

  return tests.some((test) => test.name === name.toLowerCase());
};
