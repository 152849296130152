/* global fbq */
export function enableFacebookPixel() {
  if (typeof fbq === "undefined") return;
  fbq("consent", "grant");
}

export function sendFacebookPixelEvent(eventName, event) {
  if (typeof fbq === "undefined") return;
  fbq("track", eventName, event);
}
