import xhr from "./xhr.js";
import { Logger } from "@fruugo/utilities";

const Log = new Logger(window.environment);

const regex = /marketplace\/i18n\/messages_([a-z]{2})-(.*)\.properties/;

/**
 * @param {string} url
 * @returns {Promise<Object<string,string>>}
 */
function getTranslations(url) {
  return xhr(url)
    .then((data) => data.response)
    .then(parsePropertiesFile);
}

/**
 * Convert the contents of an i18n properties file into a key/value object
 * @param {string} contents
 * @returns {Object<string,string>}
 */
function parsePropertiesFile(contents) {
  const object = {};

  contents.split("\n").forEach((line) => {
    if (line.trim() === "") return; // ignore blank lines
    if (line[0] === "#") return; // ignore comments
    const [key, ...rest] = line.split("=");
    const value = rest.join("=");
    object[key.trim()] = value.trim();
  });

  return object;
}

/**
 * Convert a template string, i.e
 * "Hello {0}", ["bob"] => "Hello Bob"
 * @param {string} string
 * @param {Array<string>} params
 * @returns {string}
 */
function substitute(string, params = []) {
  let str = string;
  params.forEach((param, i) => {
    str = str.replace("{" + i + "}", param);
  });
  return str;
}

// any in flight requests for message files
const inFlight = {};

// in memory cache of translations
const cache = {};

/**
 * pre-warm the i18n cache by pulling the keys for a given language out of storage or across the network
 * @param {string} resourceUrl
 * @returns {Promise}
 */
function initTranslation(resourceUrl = window.messagePropertiesResourceUrl) {
  const [_, language, newVersion] = resourceUrl.match(regex) || [];

  const storageKey = `i18n_${language}`;
  const versionKey = `i18n_${language}_version`;

  const cachedVersion = sessionStorage.getItem(versionKey);

  if (newVersion === cachedVersion) {
    const translations = sessionStorage.getItem(storageKey);

    if (translations !== null) {
      const parsed = JSON.parse(translations);
      cache[language] = parsed;
      return Promise.resolve(parsed);
    }
  }

  if (inFlight[language]) {
    // optimisation - skip loading the same language again if it's already in transit
    return inFlight[language];
  }

  const promise = getTranslations(resourceUrl)
    .then((translations) => {
      sessionStorage.setItem(storageKey, JSON.stringify(translations));
      sessionStorage.setItem(versionKey, newVersion);
      cache[language] = translations;
      inFlight[language] = false;
    })
    .catch(() => {
      inFlight[language] = false;
    });

  inFlight[language] = promise;

  return promise;
}

/**
 * @param {string} key
 * @param {Array<string>|string} params
 * @param {string} language
 * @returns string
 */
function i18n(key, params = [], language = window.currentLang) {
  const translations = cache[language];

  if (!translations) {
    Log.warning(
      `translations for language "${language}" not loaded, unable to translate key "${key}"`
    );
    return key;
  }

  const translation = translations[key];

  if (!translation) {
    Log.warning(
      `no translation found for language "${language}" and key "${key}"`
    );
    return key;
  }

  if (Array.isArray(params) === false) {
    params = [params];
  }

  return substitute(translation, params);
}

function waitForTranslations(language = window.currentLang) {
  if (cache[language]) return Promise.resolve();
  if (inFlight[language]) return inFlight[language];
  return Promise.reject("no translations loaded within time limit");
}

export { initTranslation, i18n, waitForTranslations };
