import xhr from "./xhr.js";
import Glide, {
  Autoplay,
  Controls,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import {
  imageFormats,
  heroImagesSizes,
  cmsMasonaryImagesSizes,
} from "./responsive-images.js";
import { Logger } from "@fruugo/utilities";

const Log = new Logger(window.environment);

const commaSeparate = (prev, current, index) =>
  `${prev}${index > 0 ? `,` : ``}${current}`;
const commaSeparatedImageFormats = imageFormats.reduce(commaSeparate, "");
const commaSeparatedHeroSizes = heroImagesSizes.reduce(commaSeparate, "");
const commaSeparatedCmsImageSizes = cmsMasonaryImagesSizes.reduce(
  commaSeparate,
  ""
);

export default function initCMS(
  endpoints = {
    hero: `/marketplace/api/home/hero?language=${window.currentLang}&sizes=${commaSeparatedHeroSizes}&formats=${commaSeparatedImageFormats}`,
    promo: `/marketplace/api/home/promo?language=${window.currentLang}&sizes=${commaSeparatedCmsImageSizes}&formats=${commaSeparatedImageFormats}`,
  }
) {
  getDataFromCms(endpoints.hero)
    .then((data) => {
      const hero = document.getElementById("cms-hero");
      if (data.theme === null || data.items === null) return;
      hero.classList.add(`theme-${data.theme}`);
      updateBannersHero(data.items, hero);
      initBannersHero(hero);
    })
    .catch((error) => Log.error(error));

  getDataFromCms(endpoints.promo)
    .then((data) => {
      const target = document.getElementById("cms-masonry");
      if (data.theme === null || data.items === null) {
        target.style.display = "none";
      } else {
        target.classList.add(`theme-${data.theme}`);
        updateCmsGrid(data.items, target);
      }
    })
    .catch((error) => Log.error(error));
}

/**
 *
 * @param endpoint
 * @returns {boolean}
 */
function cmsDataExistsForCurrentLanguage(endpoint) {
  return !!sessionStorage.getItem(`CMS_${window.currentLang}:${endpoint}`);
}

/**
 *
 * @param endpoint
 * @returns {Promise<{object}>}
 */
function getDataFromCms(endpoint) {
  return new Promise((resolve, reject) => {
    if (!cmsDataExistsForCurrentLanguage(endpoint)) {
      xhr(endpoint)
        .then((data) => {
          const homeCmsData = JSON.parse(data.response);
          sessionStorage.setItem(
            `CMS_${window.currentLang}:${endpoint}`,
            JSON.stringify(homeCmsData)
          );
          resolve(homeCmsData);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      const homeCmsData = JSON.parse(
        sessionStorage.getItem(`CMS_${window.currentLang}:${endpoint}`)
      );
      resolve(homeCmsData);
    }
  });
}

/**
 *
 * @param data
 * @param target
 */
function updateCmsGrid(data, target) {
  let i = 0;
  if (target) {
    for (const child in target.children) {
      if (Object.hasOwnProperty.call(target.children, child)) {
        const element = target.children[child];
        const item = data[i];
        updateCmsGridItem(item, element);
      }
      i++;
    }
  }
}

/**
 *
 * @param item
 * @param element
 */
function updateCmsGridItem(item, element) {
  const picture = createPictureElement(
    item.imageUrl,
    item.imageSizes,
    item.title,
    "Item__Image"
  );
  element.querySelector(".Item__Placeholder__Container").prepend(picture);

  element.querySelector(".Item__Overlay").classList.remove("bg-white");
  element.querySelector(".Item__Title").innerHTML = item.title;
  if (item.subtitle === null) {
    element.querySelector(".Item__Subtitle").style.display = "none";
  } else {
    element.querySelector(".Item__Subtitle").innerHTML = item.subtitle;
  }
  const link = document.createElement("a");
  link.innerHTML = `${item.cta.text}&nbsp;&rarr;`;
  link.setAttribute("href", item.cta.href);
  element.querySelector(".Item__Link").appendChild(link);
  element.querySelector(".loading").remove();
  element.addEventListener(
    "click",
    () => {
      window.location.href = item.cta.href;
    },
    false
  );
}

function updateBannersHero(banners, target) {
  if (!target) return;
  const wrapper = target.querySelector(".glide__slides");
  const template = target.querySelector(".glide__slide");
  banners.slice(0, 5).forEach((banner) => {
    const slide = renderBannersHeroSingle(banner, template.cloneNode(true));
    wrapper.appendChild(slide);
  });
  template.remove();
}

/**
 *
 * @param department
 * @param element
 * @returns {HTMLDivElement}
 */
function renderBannersHeroSingle(item, element) {
  element.firstElementChild.classList.remove("bg-transparent");
  const picture = createPictureElement(
    item.imageUrl,
    item.imageSizes,
    item.title,
    "Home__Hero__Item__Image"
  );
  element.appendChild(picture);

  element.querySelector("h1").innerHTML = item.title;
  element.querySelector("p").innerHTML = item.subtitle;
  element.querySelector(".d-none").classList.remove("d-none");
  element.querySelector("a").href = item.cta.href;
  element.style.opacity = 0;
  element.addEventListener(
    "click",
    () => {
      window.location.href = item.cta.href;
    },
    false
  );
  return element;
}

/**
 *
 * @param url
 * @param sizes
 * @param alt
 * @param cssClass
 * @returns {HTMLPictureElement}
 */
function createPictureElement(url, sizes, alt, cssClass) {
  const picture = document.createElement("picture");
  if (cssClass) picture.className = cssClass;

  imageFormats.forEach((format) => {
    const source = document.createElement("source");
    source.srcset = sizes
      .filter((size) => size.format === format)
      .reduce(
        (prev, current) => `${current.src} ${current.width}w,${prev}`,
        ""
      );
    source.type = `image/${format.toLowerCase()}`;
    picture.appendChild(source);
  });

  const image = new Image();
  image.src = url;
  if (cssClass) image.className = cssClass;
  image.alt = alt;

  picture.appendChild(image);

  return picture;
}

/**
 *
 * @param target
 * @returns {Glide}
 */
function initBannersHero(target) {
  return new Glide(target, {
    type: "carousel",
    perView: 1,
    autoplay: 5000,
    animationDuration: 0,
    direction: window.rtl === "true" ? "rtl" : "ltr",
    gap: 0,
  }).mount({ Autoplay, Controls, Swipe });
}
