// un-obfuscated, un-iffied version of hotjar snippet
// wrapped in a function to allow for deferred call
export function initHotjar() {
  const initialised = window.hj !== undefined;
  if (initialised) return;

  window.hj =
    window.hj ||
    function () {
      (window.hj.q = window.hj.q || []).push(arguments);
    };

  window._hjSettings = {
    hjid: window.hotjarSiteId,
    hjsv: 6,
  };

  const script = document.createElement("script");
  script.src =
    "https://static.hotjar.com/c/hotjar-" +
    window._hjSettings.hjid +
    ".js?sv=" +
    window._hjSettings.hjsv;
  script.async = 1;

  const head = document.getElementsByTagName("head")[0];
  head.appendChild(script);
}
